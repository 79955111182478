.about-wrapper {

  & {
    width: 100%;
    min-height: calc(100vh - 63px);
  }

  .sound-icon {
    width: 70%;
    height: 70%;
  }

  .sound-btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 45px;
    right: 10px;
    width: 40px;
    height: 40px;
    opacity: 0.2;
    border-radius: 20px;
    cursor: pointer;
    animation: soundBtnFadeIn 5s ease-out;
  }

  @keyframes soundBtnFadeIn {
    0% {
      // top: 0;
      opacity: 0.0;
    }
    33% {
      // top: 0;
      opacity: 0.0;
    }
    100% {
      opacity: 0.2;
      // top: 45px;
    }
  }
  
  .sound-btn-wrapper:hover {
    opacity: 0.4;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .hypercube-img {
    position: absolute;
    top: 50px;
    left: 20px;
    font-size: 1.3em;
    user-select: none;
    font-weight: bold;
    // text-shadow: 2px 2px 2px rgb(223, 221, 221);
    // background: #f3f3f3;
    // background-image: url('../../../img/hypercube.png');
    // background-size: 35px 35px;
    // border: 2px outset #d4d4d4;
    // border-radius: 15px;
    width: 35px;
    height: 35px;
    text-align: center;
    cursor: pointer;
  }

  .hypercube-img:hover {
    opacity: 0.7;
  }

  #c1 {
    max-width: 120vw;
    max-width: 120vw;
    // overflow: hidden;

  }

  .canvas-wrapper {
    z-index: -1;
    
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    // width: 98;
    height: 100vh;
    max-height: 100vh;
    max-width: 100vw;
    overflow: hidden;

    opacity: 0.2;

    transition: opacity 2s linear;
    animation: canvasIntro 5s ease-out;
  }
  
  @keyframes canvasIntro {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }

    100% {
      opacity: 0.2;
    }
  }

  .about-content-wrapper {
    width: inherit;
    margin: 20px 0 0;
    padding-left: 40px;
    opacity: 1;

    animation: aboutContentIntro 1.5s;
  }

  @keyframes aboutContentIntro {
    0% {
      margin: 0 0 0;
      opacity: 0;
    }

    100% {
      margin: 20px 0 0;
      opacity: 1;
    }
  }

  .profile-image-wrapper {
    width: 250px;
    height: 250px;

    img {
      width: inherit;
      height: inherit;
    }
  }

  .about-text-wrapper {
    width: auto;
    max-width: 800px;
    border-top: 1px solid #d9f9ff; // e9f8ff
    padding-top: 20px;
    border-radius: 0 40px 0 0;
  
    animation: aboutContentTextIntro 1.5s;
  }

  @keyframes aboutContentTextIntro {
    0% {
      padding-top: 100px;
    }

    100% {
      padding-top: 20px;
    }
  }

}
/* mobile breakpoint */
@media screen and (max-width: 768px) {
  .about-wrapper {
    
    .about-content-wrapper {
      padding: 0 15px 50px;

      animation: aboutContentIntro 1.5s;
    }

    .profile-image-wrapper {
      width: 200px;
      height: 200px;
      // margin: 0 auto;
    }
  }
}
// @media screen and (max-width: 1100px) {
// .about-wrapper {
//   #c1 {
//     width: inherit;
//     height: inherit;
//     left: 400px;
//   }
// .canvas-wrapper {
  
//   width: 70vw;
//   height: 70vh;
// }
// }
// }