
$hoverTransition: opacity 0.4s 0.2s;

$soundColorBackside: #fff;
$soundColor: #fafafa;
$soundColorEnabled: #bbffcc;
$soundColorEnabledBackside: #33ff66;
$soundColorWave: #e7e7e7;
$soundColorWaveEnd: #fff;

@mixin soundIconAttributes {
  
  width: 1em; 
  height: 1em; 
  position: relative;
  top: 5.5px;
  left: 5px;
  
  font-size: 4px;
}

@mixin makeUnselectable($disablePointer: false) {
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;

  @if($disablePointer) {
    pointer-events: none;
  }
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.game-wrapper {

  & {
    padding: 0; // mine: 528.6 (1057) 297.35 (595) WR: 1.77770304355137‬
    margin: 0; // 279 157 WR: 1.777777777777778 (16:9)
    position: relative;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    cursor: pointer;
  }

  .game-wrapper {
    position: relative;
  }

  .game-container {
    position: relative;
    width: inherit;
    height: inherit;
    min-height: 18.585vw;
    z-index: 3;

    color: white;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.8);

    opacity: 0;
    transition: $hoverTransition;
  }

  .game-container-b {
    position: relative;
    width: inherit;
    height: inherit;
    min-height: 18.585vw;

    color: white;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.8);
  }

  .game-link-a {
    display: inherit;
    width: inherit;
    height: inherit;
    min-height: inherit;
  }

  // Weird, the A tag generated by <Link> wasn't picking up so had to add class to it...
  .game-video-wrapper a:hover, .game-video-wrapper a:visited, .game-video-wrapper a:hover, .game-link-a {
    text-decoration: none;
  }

  .game-video-wrapper video {
    pointer-events: none;
  }

  /* Game title */
  .game-container h1 {
    @include makeUnselectable(true);

    margin: 5px 0 0 9px;
    font-size: 1.8em;
    font-weight: bold;
  }

  /* Game release date */
  .game-container h2 {
    @include makeUnselectable(true);

    margin: 5px 0 0 9px;
    padding: 0;
    font-size: 1.0em;
    font-weight: normal;
    opacity: 0.8;
  }

  /* Game (short) description */
  .game-container h3 {
    @include makeUnselectable(true);

    display: block;
    position: absolute;
    left: 9px;
    bottom: 5px;
    font-size: 1.1em;
    font-weight: normal;
  }

  .game-platform {
    @include makeUnselectable(true);

    width: 40px;
    height: 40px;
    position: absolute;
    top: 5px;
    right: 7px;
    opacity: 0.5;
  }

  .game-platform img {
    width: inherit;
    height: inherit;
  }

  .game-video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: black;

    opacity: 0;
    transition: $hoverTransition;
  }

  .game-video-wrapper video {
    width: inherit;
    height: inherit;


  }

  .game-sound-button-wrapper {
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 5px;
    right: 7px;

    border: 1px solid white;
    border-radius: 20px;
    background: transparent;
  }
  
  .sound-disabled {
    @include soundIconAttributes();

    box-shadow: 0 1em 0 0 $soundColorBackside, 
                0 2em 0 0 $soundColorBackside,
                1em 0em 0 0 $soundColor,
                1em 1em 0 0 $soundColor,
                1em 2em 0 0 $soundColor,
                1em 3em 0 0 $soundColor;
  }
  .sound-enabled {
    @include soundIconAttributes();

    box-shadow: 0 1em 0 0 $soundColorEnabledBackside, 
                0 2em 0 0 $soundColorEnabledBackside,
                1em 0em 0 0 $soundColorEnabled,
                1em 1em 0 0 $soundColorEnabled,
                1em 2em 0 0 $soundColorEnabled,
                1em 3em 0 0 $soundColorEnabled,
                3em 0em 11px 0 $soundColorWave,
                3em 1em 11px 0 $soundColorWave,
                3em 2em 11px 0 $soundColorWave,
                3em 3em 11px 0 $soundColorWave,
                4em 1em 11px 0 $soundColorWaveEnd,
                4em 2em 11px 0 $soundColorWaveEnd;
  }

  @media screen and (max-width: 720px) {
    .game-container {
      min-height: 56.25vw;
    }
  }
}