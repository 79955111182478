/**
 * nav.scss
 * 
 * Contains styling for the navigation bar
 * On the INDEX page, there is an animation.
 */

.navbar-spacing {
  width: 100%;
  height: $navbarHeight;
}

.navbar-wrapper {

  /* ==========================================================================
                          Navigation bar (bootstrap)
   ========================================================================== */

  .navbar {
    padding: 0 10px;
    border: none;
    border-radius: 0px;
    width: 100%; 
    position: fixed; 
    z-index: 500; 
    background: linear-gradient(#ddf, #bbf );
    // background: #eaeaff;
    background: #e9f8ff;

    background-image: url('../../img/bg-highlight.png');
    background-repeat: repeat-x;
  }

  navbar navbar-default, .navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border: none;
  }

  a {
    color: #000;
    text-decoration: none;
  }


  /* Brand (left)
   ========================================================================== */

  .navbar .navbar-brand  {
    color: #000;
    margin-right: 0;
  } 
  
  .navbar-brand .header-hypercube {
    display: inline;
    width: auto;
    height: 20px;
    vertical-align: none;
  }


  /* Navigation links (right)
   ========================================================================== */

  .navbar-icon {
    display: inline;
    margin-right: 1px;
    width: 18px;
    height: auto;
    transform: rotate(0deg) scale(1, 1);
  }

  .navbar .navbar-nav > ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .navbar .navbar-nav > ul > li {
    float: left;
    margin-right: 10px;
    margin-left: 10px;
  }

  .navbar .navbar-nav > ul > li > a {
    background-color: none;
    transition: background-color 0.7s;
  }

  .navbar .navbar-nav > ul > li > a:hover {
    background-color: rgba(255,255,255,0.3);
  }

  .blue {
    color: #00f;

    transition: color 1s;
  }

  .navbar .navbar-nav > ul > li > a:hover .navbar-icon {
    animation: navbarIconSpin 4s infinite  0.2s;
  }

  .navbar .navbar-nav > ul > li > a:hover .blue {
    color: rgb(118, 200, 255);
  }

  @keyframes navbarIconSpin {
    0% {
      transform: rotate(0deg) scale(1, 1);
    }
    30% {
      transform:  rotate(181deg) scale(0.8, 0.8) ;
    }
    60% {
      transform:  rotate(181deg) scale(0.7, 0.7) ;
    }
    100% {
      transform: rotate(360deg) scale(1, 1);
    }
  }


  /* Navigation fade-in
   ========================================================================== */

   .navbar-fade-in {
    opacity: 1;
    animation: nav-intro 6s;
  }

  .navbar-fade-in-fast {
    opacity: 1;
    animation: nav-intro 1s;
  }

  @keyframes nav-intro {
    0% {
        opacity: 0;
    }
    40% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }

  .navbar-mobile-dropdown {
    display: none;
    width: 27px;
    height: 27px;
    border: 1px solid #00f;
    border-radius: 3px;
    cursor: pointer;
    clear: both;
    float: right;
  }

  .navbar-mobile-dropdown:hover {
    background: rgba(255, 255, 255, 0.3);
    opacity: 0.8;
  }

  .navbar-line {
    border-top: 1px solid #00f;
    width: 70%;
    left: 15%;
    height: 1px;
    position: relative;
    display: block;
  }

  .navbar-line:nth-child(1) {
    top: 23%
  }
  .navbar-line:nth-child(2) {
    top: 46%
  }
  .navbar-line:nth-child(3) {
    top: 69%
  }

  
}

/* mobile viewport */
@media screen and (max-width: 550px) {
  .navbar-wrapper {

    .navbar {
      display: block;
    }

    .navbar-mobile-dropdown {
      margin: 6px 0;
    }

    .navbar-nav .navbar-right {
      float: left;
    }

    .navbar-links {
      display: none;
    }

    .navbar-opened .navbar-links {
      display: block;
    }

    .navbar-mobile-dropdown {
      display: block;
    }
    .navbar .navbar-nav > ul {
      // display: none;
    }
  
    .navbar .navbar-nav > ul > li {
      width: 100%;
      margin: 0;
    }
  
    .navbar .navbar-nav > ul > li > a {
      display: block;
      height: 40px;
      line-height: 40px;
      width: 100%;
      border-top: 1px solid lightblue;
    }
    
    .navbar-line-graphic {
      display: block;
    }
  
    // .navbar .navbar-nav > ul > li > a:hover {
    //   background-color: rgba(255,255,255,0.3);
    // }

  }
}