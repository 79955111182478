.not-found-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url('../../img/404.png');
  background-position: center;
  background-repeat: no-repeat;

  min-height: calc(100vh - 65px);
}