.featured-wrapper {
  
  .featured-items {
    display: block;
    margin: 0 auto;
    border: 1px solid #e9f8ff;
    border-radius: 5px;
    width: auto;
    max-width: 1000px;
    height: auto;
    background: #fff;
  }

  .featured-items-header {
    margin-top: 15px;
    border-bottom: 1px solid #f9f9f9;
  }

  .featured-items-header h1 {
    margin-left: 50px;
  }

  .featured-container {
    padding-left: 0;
    padding-right: 0;
  }

}