// Games view page
.games-wrapper {

  & {
    width: 100%;
    height: 100%;

    color: white;
    background: #1c0c42;
    background: linear-gradient(#140a2a, #000000);
  }

  .genre-wrapper {
    margin-bottom: 80px;
  }

  .genre-games-wrapper {
    display: flexbox;
    padding: 0;
  }

  .genre-header {
    padding-top: 10px;
    width: 100%;
    height: 70px;

    background: linear-gradient( rgba(255, 255, 255, 0.048) 0%,  rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0)  100%);
  }

  .genre-header h1 {
    font-size: 2em;
  }

  .games-genre-img {
    margin: 0px 10px 5px 15px;
    border: 1px solid #000;
    border-radius: 10px 10px 10px 10px;
    width: 35px;
    height: 35px;
    background: #eee;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.3);
  }

  .genre-upcoming-content {
    padding-top: $navbarHeight + 10px;
    padding-bottom: $navbarHeight + 10px;
    width: 100%;
    height: auto;
    min-height: 100vh;

    

    box-shadow: 0 -3px 8px rgba(0,0,0,0.5);

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .genre-upcoming-img {
    max-width: 100%;
    height: auto;
  }

  .upcoming-video-wrapper {
    margin: 20px 0;
  }

  .genre-upcoming-description {
    margin: 20px 5px;
    height: 200px;
  }

  .upcoming-video-wrapper video {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
  }

  @media screen and (max-width: 720px) {
    .upcoming-video-wrapper video {

    }
  }

  .genre-upcoming {
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 3px 3px 3px rgba(0,0,0,0.3);
  }

  .genre-upcoming h1 {
    padding: 30px 0 0;
    margin: 0;
    text-align: center;
  }
  
  .genre-upcoming h2 {
    margin: 0 0 10px;
    padding: 0 0 10px;
    font-weight: normal;
    color: rgba(255, 255, 255, 0.5);
    font-size: 0.7em;
    border-bottom: 1px solid rgba(50, 50, 255, 0.3);
    text-align: center;
  }
  
  .genre-upcoming h3 {
    font-weight: normal;
    font-size: 1.3em;
  }
  
  .genre-upcoming-wrapper {
    margin-top: 50px;
  }

  .upcoming-game-header-text {
    margin: 0;
    padding: 25px 0;
    // border-bottom: 3px solid rgba(169, 213, 255, 0.623);

    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 255, 0.8);

    background: linear-gradient(rgba(29, 18, 78, 0.048) 0%, rgba(54, 90, 255, 0.151) 50%, rgba(107, 184, 255, 0.322) 100%);

    // background:#c4c4ff;
    // background-repeat: repeat-x;
    // background-image: url('../../../img/bg-highlight.png');
    // background-size: 100% auto;
    
    animation: upcomingTextGlow 1s infinite alternate;
  }

  @keyframes upcomingTextGlow {
    0% {
      text-shadow: 2px 2px 4px rgba(0, 0, 255, 0.8);
    }
    100% {
      text-shadow: 2px 2px 20px rgba(255, 111, 255, 1);
    }
  }

}

// Games Route page (view game)
.game-route-wrapper {

  & {
    background: #001;
    min-height: 100vh;
    color: white;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .play-game-header {
    display: flex;
    width: auto;
    height: auto;
    margin: 10px auto 0;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
  }
  
  .play-game-header img {
    display: block;

    min-height: 70px;
    font-weight: bold;
    font-size: 2em;
    text-shadow: 1px 1px 5px blue;
  }
  
  .play-game-info-button {
    $side: 20px;

    margin: 0 auto 40px;
    width: $side;
    height: $side;
    border-radius: $side / 2;

    font-family:  monospace;
    color: black;
    text-align: center;
    vertical-align: center;

    font-size: 0.9em;
    background: gray;

    opacity: 0.4;

    user-select: none;
    cursor: pointer;
  }

  .play-game-info-button:hover {
    opacity: 0.5;
  }

  .game-download-instructions {
    margin-bottom: 40px;
    text-align: center;
  }

  .save-files-text {
    color: #aaa;
  }

  .game-download-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
  }

  button {
    border-radius: 0;
  }

  .game-image {
    display: block;
    position: relative;
    width: 200px;
    height: auto;
    margin: 0 auto 30px;
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.5);
    cursor: pointer;

    opacity: 1;

    transition: opacity 0.5s, box-shadow 1s;
    
    .download-game-img {
      position: absolute;
      width: 80px;
      height: auto;
      left: 30%;
      top: 15%;
      opacity: 0;
      // box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.5);

      filter: drop-shadow(1px 5px 5px rgba(21, 97, 238, 0.603)); 

    // -webkit-filter: drop-shadow(12px 12px 25px rgba(0,0,0,0.5));
    // filter: url(#drop-shadow);
    // -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#444')";
    // filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#444')";

      transition: opacity 1s;
    }

  }

  .game-image:hover {
    box-shadow: 0 0 20px 5px rgba(68, 249, 255, 0.794);

    opacity: 0.8;

    .download-game-img {
      opacity: 0.8;
    }
  }

  .game-image img {
    width: inherit;
    height: inherit;
  }

  .play-game-game > div {
    flex-basis: 1;
    // box-sizing: border-box;
  }

  .play-game-game {
    display: block;
    max-width: 600px;
    margin: 0 auto 30px;
    padding: 20px 0;
    
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%,
                                          rgba(255, 255, 255, 0) 20%,
                                          rgba(0, 1, 88, 0.315), 50%,
                                          rgba(255, 255, 255, 0) 80%,
                                          rgba(255, 255, 255, 0) 100%);

    opacity: 1.0;

    animation: fadeInGame 2s ease-in;
  }

  @keyframes fadeInGame {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1.0;
    }
  }

  #flash-object {
    box-shadow: 0 0 8px 2px rgba(0, 0, 255, 0.089);
  }

  .play-game-info {
    display: flex;
    margin-bottom: 30px;
    padding: 25px;
    height: auto;
    max-width: 1000px;
    max-height: 400px;
    justify-content: space-around;
    opacity: 1;

    background: rgba(255, 255, 255, 0.13);
    background: linear-gradient( rgba(255, 255, 255, 0.13),  rgba(255, 255, 255, 0.048));

    visibility: visible;
    transition: max-height 1s ease-out, opacity 2s;

    overflow: hidden;
  }

  .hide-info {
    max-height: 0px;
    visibility: hidden;
    opacity: 0;
  }

  /* Was going to do this, but was unnecessary */
  .hide-info-heightless {
    max-height: 0px;
  }

  .game-instructions {
    flex-basis: 100%;
    margin-right: 10px;

    border-right: 1px solid rgb(255, 255, 255);
  }

  .game-description {
    padding: 0 10px 10px;
  }

}